@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  font-family: "Comfortaa", cursive;
}

main {
  min-height: 80vh;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-BlackCondensed.0f5d6378.eot);
    src: local('DIN Next LT Pro Black Condensed'), local('DINNextLTPro-BlackCondensed'),
        url(/static/media/DINNextLTPro-BlackCondensed.0f5d6378.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-BlackCondensed.7217cac1.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-BlackCondensed.1b223e06.woff) format('woff'),
        url(/static/media/DINNextLTPro-BlackCondensed.c49f6ad8.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Regular.c350c855.eot);
    src: local('DIN Next LT Pro Regular'), local('DINNextLTPro-Regular'),
        url(/static/media/DINNextLTPro-Regular.c350c855.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Regular.f5390af4.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Regular.638192f6.woff) format('woff'),
        url(/static/media/DINNextLTPro-Regular.08c2c37f.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Medium.f88a684d.eot);
    src: local('DIN Next LT Pro Medium'), local('DINNextLTPro-Medium'),
        url(/static/media/DINNextLTPro-Medium.f88a684d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Medium.4b068c06.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Medium.f0455d6f.woff) format('woff'),
        url(/static/media/DINNextLTPro-Medium.b5aa4088.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-BoldItalic.7f30507d.eot);
    src: local('DIN Next LT Pro Bold Italic'), local('DINNextLTPro-BoldItalic'),
        url(/static/media/DINNextLTPro-BoldItalic.7f30507d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-BoldItalic.beb507cf.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-BoldItalic.182120f6.woff) format('woff'),
        url(/static/media/DINNextLTPro-BoldItalic.e307818c.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-UltraLightCond.9feef892.eot);
    src: local('DIN Next LT Pro UltraLight Condensed'), local('DINNextLTPro-UltraLightCond'),
        url(/static/media/DINNextLTPro-UltraLightCond.9feef892.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-UltraLightCond.6b59e449.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-UltraLightCond.3dd6ba61.woff) format('woff'),
        url(/static/media/DINNextLTPro-UltraLightCond.0eda028d.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url(/static/media/DINNextLTPro-BoldCondensed.38e1ca0d.eot);
    src: local('DIN Next LT Pro Bold Condensed'), local('DINNextLTPro-BoldCondensed'),
        url(/static/media/DINNextLTPro-BoldCondensed.38e1ca0d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-BoldCondensed.ef1c4ce7.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-BoldCondensed.31749c5f.woff) format('woff'),
        url(/static/media/DINNextLTPro-BoldCondensed.f37d7569.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url(/static/media/DINNextRoundedLTPro-Light.9f2a7aed.eot);
    src: local('DIN Next Rounded LT Pro Light'), local('DINNextRoundedLTPro-Light'),
        url(/static/media/DINNextRoundedLTPro-Light.9f2a7aed.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextRoundedLTPro-Light.953f6842.woff2) format('woff2'),
        url(/static/media/DINNextRoundedLTPro-Light.13d9ecac.woff) format('woff'),
        url(/static/media/DINNextRoundedLTPro-Light.0108b9e3.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-UltraLightIt.55078a0b.eot);
    src: local('DIN Next LT Pro UltraLight Italic'), local('DINNextLTPro-UltraLightIt'),
        url(/static/media/DINNextLTPro-UltraLightIt.55078a0b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-UltraLightIt.657c4ece.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-UltraLightIt.0cae52c5.woff) format('woff'),
        url(/static/media/DINNextLTPro-UltraLightIt.25ec5b23.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-UltraLight.496ec8df.eot);
    src: local('DIN Next LT Pro UltraLight'), local('DINNextLTPro-UltraLight'),
        url(/static/media/DINNextLTPro-UltraLight.496ec8df.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-UltraLight.b31f2d2e.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-UltraLight.26359c42.woff) format('woff'),
        url(/static/media/DINNextLTPro-UltraLight.3f92eedb.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Condensed.8ad8baac.eot);
    src: local('DIN Next LT Pro Condensed'), local('DINNextLTPro-Condensed'),
        url(/static/media/DINNextLTPro-Condensed.8ad8baac.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Condensed.ac56bd60.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Condensed.24404e3b.woff) format('woff'),
        url(/static/media/DINNextLTPro-Condensed.61b3ddc4.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Italic.687864fe.eot);
    src: local('DIN Next LT Pro Italic'), local('DINNextLTPro-Italic'),
        url(/static/media/DINNextLTPro-Italic.687864fe.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Italic.0019e0c1.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Italic.fa5b4fad.woff) format('woff'),
        url(/static/media/DINNextLTPro-Italic.feda1da1.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url(/static/media/DINNextRoundedLTPro-Medium.596dd5f1.eot);
    src: local('DIN Next Rounded LT Pro Medium'), local('DINNextRoundedLTPro-Medium'),
        url(/static/media/DINNextRoundedLTPro-Medium.596dd5f1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextRoundedLTPro-Medium.cb2c18c5.woff2) format('woff2'),
        url(/static/media/DINNextRoundedLTPro-Medium.0fdd738e.woff) format('woff'),
        url(/static/media/DINNextRoundedLTPro-Medium.f244d8be.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Heavy.2f270e45.eot);
    src: local('DIN Next LT Pro Heavy'), local('DINNextLTPro-Heavy'),
        url(/static/media/DINNextLTPro-Heavy.2f270e45.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Heavy.c4d7f437.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Heavy.abe97075.woff) format('woff'),
        url(/static/media/DINNextLTPro-Heavy.e3aa84e5.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-HeavyItalic.63811871.eot);
    src: local('DIN Next LT Pro Heavy Italic'), local('DINNextLTPro-HeavyItalic'),
        url(/static/media/DINNextLTPro-HeavyItalic.63811871.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-HeavyItalic.5fdc963f.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-HeavyItalic.2b0f47e9.woff) format('woff'),
        url(/static/media/DINNextLTPro-HeavyItalic.f5ec884f.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Bold.7af8c94b.eot);
    src: local('DIN Next LT Pro Bold'), local('DINNextLTPro-Bold'),
        url(/static/media/DINNextLTPro-Bold.7af8c94b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Bold.c410d41c.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Bold.3e59e4e2.woff) format('woff'),
        url(/static/media/DINNextLTPro-Bold.763d5fe1.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url(/static/media/DINNextRoundedLTPro-Regular.5be28215.eot);
    src: local('DIN Next Rounded LT Pro Regular'), local('DINNextRoundedLTPro-Regular'),
        url(/static/media/DINNextRoundedLTPro-Regular.5be28215.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextRoundedLTPro-Regular.b54385fd.woff2) format('woff2'),
        url(/static/media/DINNextRoundedLTPro-Regular.44895e21.woff) format('woff'),
        url(/static/media/DINNextRoundedLTPro-Regular.dcfae381.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Light.f9325264.eot);
    src: local('DIN Next LT Pro Light'), local('DINNextLTPro-Light'),
        url(/static/media/DINNextLTPro-Light.f9325264.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Light.405c43f9.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Light.fb9a6d01.woff) format('woff'),
        url(/static/media/DINNextLTPro-Light.944f331d.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url(/static/media/DINNextRoundedLTPro-Bold.39dbbba7.eot);
    src: local('DIN Next Rounded LT Pro Bold'), local('DINNextRoundedLTPro-Bold'),
        url(/static/media/DINNextRoundedLTPro-Bold.39dbbba7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextRoundedLTPro-Bold.b5486447.woff2) format('woff2'),
        url(/static/media/DINNextRoundedLTPro-Bold.e0c0ba2a.woff) format('woff'),
        url(/static/media/DINNextRoundedLTPro-Bold.529db1f5.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro Medium Cond';
    src: url(/static/media/DINNextLTPro-HeavyCondensed.5857826b.eot);
    src: local('DIN Next LT Pro Heavy Condensed'), local('DINNextLTPro-HeavyCondensed'),
        url(/static/media/DINNextLTPro-HeavyCondensed.5857826b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-HeavyCondensed.90c8db43.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-HeavyCondensed.608a16c0.woff) format('woff'),
        url(/static/media/DINNextLTPro-HeavyCondensed.2900aedf.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-LightItalic.28365d6c.eot);
    src: local('DIN Next LT Pro Light Italic'), local('DINNextLTPro-LightItalic'),
        url(/static/media/DINNextLTPro-LightItalic.28365d6c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-LightItalic.6b3878fb.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-LightItalic.e892ca95.woff) format('woff'),
        url(/static/media/DINNextLTPro-LightItalic.5af3e65d.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-LightCondensed.5a7764c3.eot);
    src: local('DIN Next LT Pro Light Condensed'), local('DINNextLTPro-LightCondensed'),
        url(/static/media/DINNextLTPro-LightCondensed.5a7764c3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-LightCondensed.2c4435b6.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-LightCondensed.9d70ff59.woff) format('woff'),
        url(/static/media/DINNextLTPro-LightCondensed.bebe112e.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-MediumItalic.ad9d4d2b.eot);
    src: local('DIN Next LT Pro Medium Italic'), local('DINNextLTPro-MediumItalic'),
        url(/static/media/DINNextLTPro-MediumItalic.ad9d4d2b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-MediumItalic.cfd0ef12.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-MediumItalic.ca55ae6e.woff) format('woff'),
        url(/static/media/DINNextLTPro-MediumItalic.afc8c829.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-Black.023f3cbe.eot);
    src: local('DIN Next LT Pro Black'), local('DINNextLTPro-Black'),
        url(/static/media/DINNextLTPro-Black.023f3cbe.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-Black.c0d2989a.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-Black.df276b38.woff) format('woff'),
        url(/static/media/DINNextLTPro-Black.d1ea9fe5.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-MediumCond.98e5e0af.eot);
    src: local('DIN Next LT Pro Medium Condensed'), local('DINNextLTPro-MediumCond'),
        url(/static/media/DINNextLTPro-MediumCond.98e5e0af.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-MediumCond.a64dc0fb.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-MediumCond.7ec0786c.woff) format('woff'),
        url(/static/media/DINNextLTPro-MediumCond.e358280c.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url(/static/media/DINNextLTPro-BlackItalic.b4975518.eot);
    src: local('DIN Next LT Pro Black Italic'), local('DINNextLTPro-BlackItalic'),
        url(/static/media/DINNextLTPro-BlackItalic.b4975518.eot?#iefix) format('embedded-opentype'),
        url(/static/media/DINNextLTPro-BlackItalic.78bfc6ff.woff2) format('woff2'),
        url(/static/media/DINNextLTPro-BlackItalic.55a4213f.woff) format('woff'),
        url(/static/media/DINNextLTPro-BlackItalic.9c2badf3.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}


body,
html {
    font-family: 'DIN Next LT Pro' !important;
}

.btn-theme2020 {
    background-color: #d57a49 !important;
    color: white !important;
}

.bg-theme2020 {
    background-color: white !important;
    color: #000000 !important;
}

.navbar-nav > li > a {
    font-family: 'DIN Next LT Pro' !important;
    font-weight: 500 !important;
    color: #3a3a3a !important;
    font-size: 17px !important;
    background-color: white !important;
    margin-right: -6px;
    margin-left: -6px;
    text-rendering: optimizelegibility;
}

    .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
        background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
        color: white !important
    }

.bg-azul2020 {
    background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
    border-color: rgb(54,86,140) /*#3a5f7e*/ !important;
}

.bg-purpura2020 {
    background-color: #98579a !important;
}

.color-purpura2020 {
    color: #98579a !important;
    
}
.bg-naranjo2020 {
    background-color: #d57a49 !important;
    color:white !important;
}

.scrollToTop {
    background-color: #d57a49 !important;
    border-color: #d57a49 !important;
}

.bg-dark2020 {
    background-color: #272727;
}

.nav > li > a {
    display: table-cell;
    padding: 10px 9px;
}

.navbar-toggle {
    background-color: #d57a49 !important;
    border-color: #d57a49 !important;
}
.navbar-toggle:hover {
    background-color: #d57a49 !important;
    border-color: #d57a49 !important;
}

.footer-li {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400 !important;
    color: #fff;
    text-decoration: none;
}
    .footer-li:hover {
        color: #d57a49 !important;
    }
    .footer-li:link{
        text-decoration: none;
    }
.footer-socialIcon {
    color: rgb(242,143,37)/*#0cc*/ !important;
    font-size: 20px !important;
    font-weight: 200 !important;
}

.navbar-pipe2020 {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: white !important;
    color: #98579a !important;
}

    .navbar-pipe2020:hover, .navbar-pipe2020:focus {
        background-color: transparent !important;
        color: #000000 !important;
    }

.navbar2030 {
    background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
    color: white !important;
    font-weight: 400 !important;
    text-transform:unset !important;
}

.navbar .dropdown-menu > li > a:hover {
    background-color: #d57a49 !important;
    color: white !important;
    font-weight: 200 !important;
}

.navbar-brand {
    padding: 8px 15px;
}

.font2020 {
    font-family: 'DIN Next LT Pro' !important;
}
.icon-holder:hover {
    background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
    border-color: rgb(54,86,140) /*#3a5f7e*/ !important;
}
.icon-holder i {
    color: white !important;
}
    .icon-holder > i:hover {
        color: rgb(54,86,140);
       
    }
@media (min-width: 992px) {
    .footer-container {
        left: 120px;
        width: 80%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

    .navbar .dropdown-menu li a {
        background-color: white !important;
        color: #000 !important;
        font-weight: 200 !important;
    }

    .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
        background-color: white !important;
        color: #000 !important;
    }

    .navbar-fixed-top .navbar-nav > li > a:hover {
        background-color: white !important;
        color: #000 !important;
    }

    .navbar .dropdown-menu li a {
        color: #3a5f7e !important;
    }
}

.call-to-action-small {
    padding: 6px 12px;
}

.navbar-scroll2 > .container > .navbar-collapse.collapse > #logo_facultad > img {
    margin-top: -7px !important;
}

@media screen and (min-width: 1920px) {
    .navbar-scroll2 > .container > .navbar-collapse.collapse > #logo_facultad > img {
        height: 3vw !important;
    }

}
.bg-gray {
    background-color: #eeeeee !important;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.blue-button{
    color: #fff;
    background-color : rgb(54,86,140);
    border-color: rgb(54,86,140);
}
.blue-button:hover{
    background-color :#d57a49;
    border-color:#d57a49 ;
    color: #fff;
    
}
.orange-button:hover{
    background-color :#d57a49;
    color: #fff;
}
.orange-button:hover{
    background-color :rgb(54,86,140);
    
}
.white-button{
    background-color  : "transparent";
    color: #fff;
    outline-color: #fff;
}
.white-button:hover{
    color: #d57a49;;
    outline-color: #d57a49;;
}

.zoom {
    transition: visibility .25s ease-in, -webkit-transform .25s;
    transition: transform .25s, visibility .25s ease-in;
    transition: transform .25s, visibility .25s ease-in, -webkit-transform .25s;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
}

    .zoom:hover {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
