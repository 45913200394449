body,
html {
    font-family: 'DIN Next LT Pro' !important;
}

.btn-theme2020 {
    background-color: #d57a49 !important;
    color: white !important;
}

.bg-theme2020 {
    background-color: white !important;
    color: #000000 !important;
}

.navbar-nav > li > a {
    font-family: 'DIN Next LT Pro' !important;
    font-weight: 500 !important;
    color: #3a3a3a !important;
    font-size: 17px !important;
    background-color: white !important;
    margin-right: -6px;
    margin-left: -6px;
    text-rendering: optimizelegibility;
}

    .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
        background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
        color: white !important
    }

.bg-azul2020 {
    background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
    border-color: rgb(54,86,140) /*#3a5f7e*/ !important;
}

.bg-purpura2020 {
    background-color: #98579a !important;
}

.color-purpura2020 {
    color: #98579a !important;
    
}
.bg-naranjo2020 {
    background-color: #d57a49 !important;
    color:white !important;
}

.scrollToTop {
    background-color: #d57a49 !important;
    border-color: #d57a49 !important;
}

.bg-dark2020 {
    background-color: #272727;
}

.nav > li > a {
    display: table-cell;
    padding: 10px 9px;
}

.navbar-toggle {
    background-color: #d57a49 !important;
    border-color: #d57a49 !important;
}
.navbar-toggle:hover {
    background-color: #d57a49 !important;
    border-color: #d57a49 !important;
}

.footer-li {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400 !important;
    color: #fff;
    text-decoration: none;
}
    .footer-li:hover {
        color: #d57a49 !important;
    }
    .footer-li:link{
        text-decoration: none;
    }
.footer-socialIcon {
    color: rgb(242,143,37)/*#0cc*/ !important;
    font-size: 20px !important;
    font-weight: 200 !important;
}

.navbar-pipe2020 {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: white !important;
    color: #98579a !important;
}

    .navbar-pipe2020:hover, .navbar-pipe2020:focus {
        background-color: transparent !important;
        color: #000000 !important;
    }

.navbar2030 {
    background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
    color: white !important;
    font-weight: 400 !important;
    text-transform:unset !important;
}

.navbar .dropdown-menu > li > a:hover {
    background-color: #d57a49 !important;
    color: white !important;
    font-weight: 200 !important;
}

.navbar-brand {
    padding: 8px 15px;
}

.font2020 {
    font-family: 'DIN Next LT Pro' !important;
}
.icon-holder:hover {
    background-color: rgb(54,86,140) /*#3a5f7e*/ !important;
    border-color: rgb(54,86,140) /*#3a5f7e*/ !important;
}
.icon-holder i {
    color: white !important;
}
    .icon-holder > i:hover {
        color: rgb(54,86,140);
       
    }
@media (min-width: 992px) {
    .footer-container {
        left: 120px;
        width: 80%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

    .navbar .dropdown-menu li a {
        background-color: white !important;
        color: #000 !important;
        font-weight: 200 !important;
    }

    .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
        background-color: white !important;
        color: #000 !important;
    }

    .navbar-fixed-top .navbar-nav > li > a:hover {
        background-color: white !important;
        color: #000 !important;
    }

    .navbar .dropdown-menu li a {
        color: #3a5f7e !important;
    }
}

.call-to-action-small {
    padding: 6px 12px;
}

.navbar-scroll2 > .container > .navbar-collapse.collapse > #logo_facultad > img {
    margin-top: -7px !important;
}

@media screen and (min-width: 1920px) {
    .navbar-scroll2 > .container > .navbar-collapse.collapse > #logo_facultad > img {
        height: 3vw !important;
    }

}
.bg-gray {
    background-color: #eeeeee !important;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.blue-button{
    color: #fff;
    background-color : rgb(54,86,140);
    border-color: rgb(54,86,140);
}
.blue-button:hover{
    background-color :#d57a49;
    border-color:#d57a49 ;
    color: #fff;
    
}
.orange-button:hover{
    background-color :#d57a49;
    color: #fff;
}
.orange-button:hover{
    background-color :rgb(54,86,140);
    
}
.white-button{
    background-color  : "transparent";
    color: #fff;
    outline-color: #fff;
}
.white-button:hover{
    color: #d57a49;;
    outline-color: #d57a49;;
}

.zoom {
    transition: transform .25s, visibility .25s ease-in;
    transform-origin: 0 0;
}

    .zoom:hover {
        transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }