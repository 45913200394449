@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-BlackCondensed.eot');
    src: local('DIN Next LT Pro Black Condensed'), local('DINNextLTPro-BlackCondensed'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackCondensed.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackCondensed.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackCondensed.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackCondensed.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Regular.eot');
    src: local('DIN Next LT Pro Regular'), local('DINNextLTPro-Regular'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Regular.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Regular.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Medium.eot');
    src: local('DIN Next LT Pro Medium'), local('DINNextLTPro-Medium'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Medium.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Medium.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-BoldItalic.eot');
    src: local('DIN Next LT Pro Bold Italic'), local('DINNextLTPro-BoldItalic'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldItalic.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightCond.eot');
    src: local('DIN Next LT Pro UltraLight Condensed'), local('DINNextLTPro-UltraLightCond'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightCond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightCond.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightCond.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightCond.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-BoldCondensed.eot');
    src: local('DIN Next LT Pro Bold Condensed'), local('DINNextLTPro-BoldCondensed'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldCondensed.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldCondensed.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldCondensed.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BoldCondensed.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Light.eot');
    src: local('DIN Next Rounded LT Pro Light'), local('DINNextRoundedLTPro-Light'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Light.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Light.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightIt.eot');
    src: local('DIN Next LT Pro UltraLight Italic'), local('DINNextLTPro-UltraLightIt'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightIt.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightIt.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLightIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-UltraLight.eot');
    src: local('DIN Next LT Pro UltraLight'), local('DINNextLTPro-UltraLight'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLight.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLight.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Condensed.eot');
    src: local('DIN Next LT Pro Condensed'), local('DINNextLTPro-Condensed'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Condensed.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Condensed.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Condensed.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Condensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Italic.eot');
    src: local('DIN Next LT Pro Italic'), local('DINNextLTPro-Italic'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Italic.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Italic.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Medium.eot');
    src: local('DIN Next Rounded LT Pro Medium'), local('DINNextRoundedLTPro-Medium'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Medium.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Medium.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Heavy.eot');
    src: local('DIN Next LT Pro Heavy'), local('DINNextLTPro-Heavy'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Heavy.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Heavy.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-HeavyItalic.eot');
    src: local('DIN Next LT Pro Heavy Italic'), local('DINNextLTPro-HeavyItalic'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyItalic.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyItalic.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Bold.eot');
    src: local('DIN Next LT Pro Bold'), local('DINNextLTPro-Bold'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Bold.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Bold.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Regular.eot');
    src: local('DIN Next Rounded LT Pro Regular'), local('DINNextRoundedLTPro-Regular'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Regular.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Regular.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Light.eot');
    src: local('DIN Next LT Pro Light'), local('DINNextLTPro-Light'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Light.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Light.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Bold.eot');
    src: local('DIN Next Rounded LT Pro Bold'), local('DINNextRoundedLTPro-Bold'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Bold.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Bold.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextRoundedLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro Medium Cond';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-HeavyCondensed.eot');
    src: local('DIN Next LT Pro Heavy Condensed'), local('DINNextLTPro-HeavyCondensed'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyCondensed.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyCondensed.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyCondensed.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-HeavyCondensed.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-LightItalic.eot');
    src: local('DIN Next LT Pro Light Italic'), local('DINNextLTPro-LightItalic'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightItalic.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightItalic.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-LightCondensed.eot');
    src: local('DIN Next LT Pro Light Condensed'), local('DINNextLTPro-LightCondensed'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightCondensed.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightCondensed.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightCondensed.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-LightCondensed.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-MediumItalic.eot');
    src: local('DIN Next LT Pro Medium Italic'), local('DINNextLTPro-MediumItalic'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumItalic.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumItalic.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-Black.eot');
    src: local('DIN Next LT Pro Black'), local('DINNextLTPro-Black'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Black.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Black.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-MediumCond.eot');
    src: local('DIN Next LT Pro Medium Condensed'), local('DINNextLTPro-MediumCond'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumCond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumCond.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumCond.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-MediumCond.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../fonts/DINNextLTPro/DINNextLTPro-BlackItalic.eot');
    src: local('DIN Next LT Pro Black Italic'), local('DINNextLTPro-BlackItalic'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackItalic.woff2') format('woff2'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackItalic.woff') format('woff'),
        url('../fonts/DINNextLTPro/DINNextLTPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

